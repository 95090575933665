import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import {
  mainContent,
  description,
  row,
  //col6,
  col12,
  marginTopSubstitute,
  introSection,
  buttonGroup,
} from "./subpage.module.scss";
//import { Link } from "gatsby";

const SprechstundePlusPage = () => {
  return (
    <Layout pageTitle="SprechstundePlusLive" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
          <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                <p className={description}>News - 29. Juni 2023</p>
                <h1>
                  Hirslanden AG führt erfolgreich das Modul ANA-Cockpit
                  (SprechstundePlus) der health-engine® ein und verbessert damit
                  Effizienz und Patientenversorgung
                </h1>

                <p className="text-medium">
                  Unser neues Modul, SprechstundePlus, revolutioniert die
                  Sprechstundenvorbereitung in medizinischen Einrichtungen.
                  Gemeinsam mit unserem Kunden haben wir es auch erfolgreich am
                  Swiss eHealth Forum 2023 präsentiert.
                </p>

                <p>
                  Im heutigen Bericht stellen wir Ihnen einen innovativen
                  Anwendungsfall der Hirslanden AG, Klinik Hirslanden Zürich für
                  unser neues{" "}
                  <a
                    className="italic-blue"
                    href="https://www.tie.ch/module/#sprechstunde"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    Modul SprechstundePlus{" "}
                  </a>{" "}
                  vor. Die Klinik Hirslanden Zürich nutzt das health-engine®
                  Modul SprechstundePlus im Rahmen von ihrer Anästhesie
                  Sprechstunde, wobei das Modul innerhalb der Hirslanden unter
                  dem Namen "ANA-Cockpit" (Anästhesie-Cockpit) bekannt ist. Nach
                  der erfolgreichen Pilotierung des ANA-Cockpits in der Klinik
                  Hirslanden Zürich, ist die Lösung nun bereit für den Rollout
                  in der Hirslanden-Gruppe. Das Modul integriert sich nahtlos in
                  die i-engineers Portale und bietet innovative Möglichkeiten
                  für einen optimierten Workflow – sowohl mit internen als auch
                  externen Stakeholdern.{" "}
                </p>

                <p>
                  Zum Video der Hirslanden-Gruppe: Digitalisierung im Bereich
                  der Anästhesie-Sprechstunde
                </p>

                <div className={buttonGroup}>
                  <a
                    href="https://www.youtube.com/watch?v=ViBkuFZ92dM"
                    className="button-outline blue"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Zum Youtube Video der Hirslanden-Gruppe
                  </a>
                </div>
                <div className={buttonGroup}>
                  <a
                    href="https://www.clinicum.ch/images/getFile?t=ausgabe_artikel&f=dokument&id=2948"
                    className="button-outline blue"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Zum Clinicum Artikel
                  </a>
                </div>

                <p style={{ marginTop: 60 }}>
                  Wir freuen uns über die erfolgreiche Einführung des neuen
                  Moduls ANA-Cockpit mit der Hirslanden AG und bedanken uns an
                  dieser Stelle herzlich für die exzellente Zusammenarbeit. Die
                  innovative Lösung optimiert den Prozess der präoperativen
                  Anästhesiesprechstunde in der Administration und reduziert
                  Medienbrüche durch Integration in die IT-Systemlandschaft.
                </p>

                <p>
                  Patienten der Klinik Hirslanden Zürich füllen nun einen online
                  zur Verfügung gestellten Gesundheitsfragebogen aus, welcher
                  Vorteile für Patienten und Mitarbeitende der Hirslanden-Gruppe
                  bringt, die als Ideengeberin in der Konzeption des Moduls
                  ausschlaggebend war.
                </p>

                <h3
                  style={{ marginTop: 30, marginBottom: 4 }}
                  className="italic"
                >
                  «Tolles Projekt, super umgesetzt. Wir freuen uns auf die
                  abteilungsweite Einführung des ANA-Cockpits»
                </h3>
                <div style={{ fontSize: 15 }} className="italic">
                  - Jürgen Gaulke, Institutsmanager IFAI, Klinik Hirslanden
                  Zürich
                </div>

                <div className={marginTopSubstitute}></div>
                <p>
                  Durch das Modul ANA-Cockpit können verschiedene Teilprozesse,
                  wie z.B. das Anfragen von Befunden an den Hausarzt angestossen
                  werden. So werden alle relevanten Daten des Patienten, von
                  Partnerärzten oder Dispositionen an einem Ort
                  zusammengestellt. Für die am Prozess Beteiligten entstehen so
                  Zeitersparnis, Effizienzsteigerungen, Teilautomatisierungen
                  und ein klarer Überblick durch Prozessstandardisierung. Die
                  entstandene Lösung dient als Vorzeigemodell für die
                  Organisation und Abwicklung medizinischer Sprechstunden aller
                  Art.
                </p>

                <StaticImage
                  src="../assets/ANA-Cockpit-Hirslanden.png"
                  alt="Screenshot ANA-Cockpit Hirslanden"
                  placeholder="none"
                  layout="fullWidth"
                  formats={["auto", "webp", "avif"]}
                />
              </div>

              <div className={col12}>
                <h3
                  style={{ marginTop: 30, marginBottom: 4 }}
                  className="italic"
                >
                  «Für mich ist die Teilautomatisierung ein grosser Gewinn. Die
                  Kollegen*innen werden von Routineaufgaben entlastet und können
                  den Stand der Bearbeitung mit einem Blick erfassen.»
                </h3>
                <div style={{ fontSize: 15 }} className="italic">
                  - Jürgen Gaulke, Institutsmanager IFAI, Klinik Hirslanden
                  Zürich
                </div>
                <div className={marginTopSubstitute}></div>

                <p>
                  Das Modul ANA-Cockpit ermöglicht eine digitale Vorbereitung
                  der Anästhesie-Sprechstunde, indem alle relevanten
                  Informationen zentral in einem Anästhesie-Dossier gesammelt
                  und übersichtlich für den Anästhesisten zur Verfügung gestellt
                  werden. Die Lösung erhält sehr positives Feedback,
                  insbesondere für die Verbesserung der Datensicherheit und die
                  Entlastung des Personals von Routineaufgaben.
                </p>

                <h3
                  style={{ marginTop: 30, marginBottom: 4 }}
                  className="italic"
                >
                  «Durch die mehrstufige Sicherheitsarchitektur der
                  health-engine® zusammen mit der transfer-engine, können
                  Spital-interne Prozesse von den externen Prozessen getrennt
                  werden. So wird sichergestellt, dass nur diejenigen Daten
                  temporär über das Internet zugänglich sind, welche in der
                  health-engine® freigegeben wurden. Das erhöht die
                  Datensicherheit für sensitive Patientendaten erheblich und
                  ermöglicht trotzdem einen sicheren Datenaustausch.»
                </h3>
                <div style={{ fontSize: 15 }} className="italic">
                  - Nico Körner, Lead Information Security Officer, Hirslanden
                </div>
                <div className={marginTopSubstitute}></div>

                <h3>
                  Der Nutzen des ANA-Cockpits der Hirslanden-Gruppe im Überblick
                </h3>
                <ul>
                  <li>Schnellere Vorbereitung der Anästhesie-Sprechstunde</li>
                  <li>Automatisierung durch strukturierte Informationen</li>
                  <li>
                    Effizientere Auslastung der Anästhesisten, wodurch die
                    Planung für die präoperative Anästhesiesprechstunde, OP
                    Präsenz und postoperative Visiten transparenter wird
                  </li>
                  <li>Sicherer Datenaustausch</li>
                  <li>
                    In Planung: Reduzierung des administrativen Aufwands bei
                    Terminbuchungen
                  </li>
                </ul>

                <h3>Beispielhafter Ablauf</h3>
                <p>
                  Wenn bei der Patientenanmeldung vermerkt wird, dass für die
                  angemeldete Operation eine Anästhesie erforderlich ist, wird
                  automatisch ein Anästhesie-Dossier in der health-engine®
                  erstellt.
                </p>

                <p>
                  Parallel erhalten Patienten via E-Mail einen Link zu einem
                  Gesundheitsfragebogen, welcher Risiken, Vorerkrankungen usw.
                  abfragt.
                </p>

                <p>
                  Der Gesundheitsfragebogen wird den Patienten über the
                  i-engineers Sicherheitsarchitektur auf der transfer-engine zur
                  Verfügung gestellt und nutzt zudem eine
                  Zwei-Faktor-Authentisierung.
                </p>

                <p>
                  Durch Klicken auf den URL-Link in der E-Mail und anschließende
                  Eingabe eines mTANs kann der Patient den Gesundheitsfragebogen
                  schnell und einfach am PC oder Handy ausfüllen.
                </p>

                <p>
                  Die Antworten werden dann im Anästhesie-Dossier
                  bereitgestellt. Die vom Patienten ausgefüllten Antworten
                  stehen der health-engine® als strukturierte Daten zur
                  Verfügung und können für weitere Automatisierungsschritte in
                  der Sprechstundenvorbereitung verwendet werden.
                </p>
              </div>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default SprechstundePlusPage;
